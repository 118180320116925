import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const dspListPlugin = createPlugin({
  id: 'dsp-list',
  routes: {
    root: rootRouteRef,
  },
});

export const DspListPage = dspListPlugin.provide(
  createRoutableExtension({
    name: 'DspListPage',
    component: () =>
      import('./components/DSPListComponent').then(m => m.DSPListComponent),
    mountPoint: rootRouteRef,
  }),
);
