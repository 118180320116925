import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/types';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  schemasAnyCapabilityConfig,
  schemasAnyModuleConfig,
  getJsonSchema,
  isProductManifest,
  ManifestHandler,
  ProductManifest,
  RegistryManifest,
  AnyCapabilityConfigKind,
  AnyModuleConfigKind,
  isStagedConfigDict,
  getTypeGuard,
} from '@provisioning/common';
import Form from '@rjsf/mui';
import { UiSchema, DescriptionFieldProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, { useCallback, useEffect, useState } from 'react';
import { extractSchemaFromStep } from './schema';
import { MarkdownContent } from '@backstage/core-components';

export type CreateDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ProductManifest) => void;
  getCurrent: () => RegistryManifest;
};

function DescriptionFieldTemplate(props: DescriptionFieldProps) {
  const { description, id } = props;
  return (
    // @ts-expect-error - we know this is a string */}
    <MarkdownContent id={id} content={description} dialect="gfm" />
  );
}

// const fields: RegistryFieldsType = { AzureGroupSearch };

export function CreateDialog(props: CreateDialogProps) {
  const { open, onClose, onSubmit, getCurrent } = props;
  const [elementType, setElementType] = useState<'module' | 'capability'>(
    'module',
  );
  const [selected, setSelected] = useState<
    AnyModuleConfigKind | AnyCapabilityConfigKind | undefined
  >(undefined);
  const errorApi = useApi(errorApiRef);
  const [formProps, setFormProps] = useState<
    { schema: JsonObject; uiSchema: UiSchema } | undefined
  >(undefined);
  const [configFormData, setConfigFormData] = useState<any>(undefined);
  const [kindOptions, setKindOptions] = useState<
    { kind: string; isUpdate: boolean }[]
  >([]);

  const handleClose = useCallback(() => {
    onClose();
    setFormProps(undefined);
    setSelected(undefined);
  }, [onClose]);

  const handleChangeType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setElementType(
        (event.target as HTMLInputElement).value as 'module' | 'capability',
      );
      setSelected(undefined);
    },
    [],
  );

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setSelected(
      event.target.value as AnyModuleConfigKind | AnyCapabilityConfigKind,
    );
  }, []);

  const handleSubmit = useCallback(
    (data: any) => {
      if (!selected) {
        errorApi.post(new Error('No selected kind'));
        return;
      }
      const handler: ManifestHandler<ProductManifest> =
        ManifestHandler.fromManifest(
          getCurrent(),
        ) as ManifestHandler<ProductManifest>;
      switch (elementType) {
        case 'module':
          handler.upsertModule(data);
          break;
        case 'capability':
          handler.upsertCapability(selected as AnyCapabilityConfigKind, data);
          break;
        default:
          throw new Error(`Unknown elementType: ${elementType}`);
      }

      onSubmit(handler.manifest);
      handleClose();
    },
    [onSubmit, handleClose, getCurrent, elementType, selected, errorApi],
  );

  useEffect(() => {
    if (!selected) {
      setFormProps(undefined);
      return;
    }

    const current = getCurrent();
    const handler = ManifestHandler.fromManifest(current);

    let formData;
    let schemaRef;
    switch (elementType) {
      case 'module':
        formData = handler.getModuleConfig(selected as AnyModuleConfigKind);
        schemaRef = schemasAnyModuleConfig[selected as AnyModuleConfigKind];
        break;
      case 'capability':
        formData = handler.getCapabilityConfig(
          selected as AnyCapabilityConfigKind,
        );
        schemaRef =
          schemasAnyCapabilityConfig[selected as AnyCapabilityConfigKind];
        if (!schemaRef) {
          throw new Error(`Capability config is not of kind ${selected}`);
        }
        if (isStagedConfigDict(formData?.config, getTypeGuard(schemaRef))) {
          formData = formData.config.development;
        } else if (formData?.config) {
          formData = formData.config;
        }
        break;
      default:
        throw new Error(`Unknown elementType: ${elementType}`);
    }

    if (!schemaRef) {
      errorApi.post(new Error(`No schema found for capability ${selected}`));
      return;
    }
    setFormProps(extractSchemaFromStep(getJsonSchema(schemaRef)));
    setConfigFormData(formData || { kind: selected });
  }, [selected, errorApi, getCurrent, elementType]);

  useEffect(() => {
    const current = getCurrent();
    if (isProductManifest(current)) {
      const handler = ManifestHandler.fromManifest(current);
      switch (elementType) {
        case 'module':
          setKindOptions(
            Object.keys(schemasAnyModuleConfig).map(key => ({
              kind: key,
              isUpdate: handler.hasModule(
                key as keyof typeof schemasAnyModuleConfig,
              ),
            })),
          );
          break;
        case 'capability':
          setKindOptions(
            Object.keys(schemasAnyCapabilityConfig).map(key => ({
              kind: key,
              isUpdate: handler.hasCapability(
                key as keyof typeof schemasAnyCapabilityConfig,
              ),
            })),
          );
          break;
        default:
          break;
      }
    }
    // we depend on 'open' to trigger the update of the capability options
    // when the dialog is opened. since the getCurrent function is not changing
    // we would otherwise not get the updated capability options
  }, [open, getCurrent, elementType]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Select component to configure
      </DialogTitle>
      <DialogContent>
        {!!!selected && (
          <>
            <FormControl>
              <RadioGroup
                name="element-type-select"
                value={elementType}
                onChange={handleChangeType}
                row
              >
                <FormControlLabel
                  value="module"
                  control={<Radio />}
                  label="Module"
                />
                <FormControlLabel
                  value="capability"
                  control={<Radio />}
                  label="Capability"
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="capability-label">
                {elementType === 'module' ? 'Module' : 'Capability'}
              </InputLabel>
              <Select
                labelId="capability-label"
                id="kind-select"
                value={selected}
                label={elementType === 'module' ? 'Module' : 'Capability'}
                onChange={handleChange}
              >
                {kindOptions.map(opt => (
                  <MenuItem key={opt.kind} value={opt.kind}>
                    <ListItemIcon>
                      {opt.isUpdate ? (
                        <EditIcon fontSize="small" />
                      ) : (
                        <AddIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText>{opt.kind}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {selected && formProps && configFormData && (
          <Form
            {...formProps}
            validator={validator}
            formData={configFormData}
            onChange={e => setConfigFormData(e.formData)}
            onSubmit={({ formData }) => handleSubmit(formData)}
            templates={{ DescriptionFieldTemplate }}
          >
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setSelected(undefined)} color="primary">
                Back
              </Button>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </DialogContent>
      {!!!selected && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
