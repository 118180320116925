/* eslint-disable new-cap */
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { LoggerService } from '@backstage/backend-plugin-api';
import { ConflictError, NotFoundError, ResponseError } from '@backstage/errors';
import crossFetch from 'cross-fetch';
import createClient from 'openapi-fetch';
import {
  CreateProductRequest,
  CreateWorkspaceRequest,
  GetMsgraphGroupsRequest,
  GetMsgraphUserMemberGroupsRequest,
  GetMsgraphUsersRequest,
  GetProductRequest,
  GetWorkspaceRequest,
  ProvisioningRegistryApi,
  QueueAllProductBuildsRequest,
  QueueAllWorkspaceBuildsRequest,
  QueueProductBuildRequest,
  RequestOptions,
  UpdateProductRequest,
  UpdateWorkspaceRequest,
} from '../api';

import type { paths } from '../api/schema';
import { ProductManifest } from '../models';

/**
 * A frontend and backend compatible client for communicating with
 * the Argus Provisioning API.
 *
 * @public
 */
export class ProvisioningRegistryClient implements ProvisioningRegistryApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly logger: LoggerService;
  private readonly fetchApi?: FetchApi;
  private fetchClient?: ReturnType<typeof createClient<paths>>;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    logger: LoggerService;
    fetchApi?: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.logger = options.logger;
  }

  private async client() {
    if (!this.fetchClient) {
      const baseUrl = await this.discoveryApi.getBaseUrl('provisioning');
      this.fetchClient = createClient<paths>({
        baseUrl: baseUrl,
        fetch: this.fetchApi?.fetch ?? crossFetch,
      });
    }
    return this.fetchClient;
  }

  private headersFromOptions(options?: RequestOptions): Record<string, string> {
    if (!options?.token) return {};
    return { Authorization: `Bearer ${options.token}` };
  }

  /**
   * Create a new product.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async createProduct(request: CreateProductRequest, options?: RequestOptions) {
    const { manifest } = request;
    const { data, response } = await this.client().then(c =>
      c.POST('/products', {
        headers: this.headersFromOptions(options),
        body: manifest,
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to create product: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Get the manifest for an existing product.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async getProductManifest(
    request: GetProductRequest,
    options?: RequestOptions,
  ) {
    const { name } = request;
    const { data: manifest, response } = await this.client().then(c =>
      c.GET('/products/{productName}', {
        headers: this.headersFromOptions(options),
        params: { path: { productName: name } },
      }),
    );
    const productManifest = manifest as ProductManifest;
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to get product manifest: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!productManifest) {
      throw new NotFoundError(`No data for product '${name}'`);
    }
    return { manifest: productManifest };
  }

  /**
   * Update an existing product.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async updateProduct(request: UpdateProductRequest, options?: RequestOptions) {
    const { manifest } = request;
    const { data, response } = await this.client().then(c =>
      c.PATCH('/products/{productName}', {
        headers: this.headersFromOptions(options),
        params: { path: { productName: manifest.metadata.name } },
        body: manifest,
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to update product: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Queue a product build.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async queueProductBuild(
    request: QueueProductBuildRequest,
    options?: RequestOptions,
  ) {
    const { name } = request;
    const { data, response } = await this.client().then(c =>
      c.POST('/products/{productName}/build', {
        headers: this.headersFromOptions(options),
        params: { path: { productName: name } },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to queue product build: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Create a new workspace.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async createWorkspace(
    request: CreateWorkspaceRequest,
    options?: RequestOptions,
  ) {
    const { manifest, createAdoProject } = request;
    const { data, response } = await this.client().then(c =>
      c.POST('/workspaces', {
        headers: this.headersFromOptions(options),
        params: {
          query: { create: createAdoProject.toString() },
        },
        body: manifest,
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to create workspace: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Get the manifest for an existing workspace.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  // @ts-expect-error
  async getWorkspaceManifest(
    request: GetWorkspaceRequest,
    options?: RequestOptions,
  ) {
    const { name } = request;
    const { data: manifest, response } = await this.client().then(c =>
      c.GET('/workspaces/{workspaceName}', {
        headers: this.headersFromOptions(options),
        params: { path: { workspaceName: name } },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to get workspace manifest: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!manifest) {
      throw new NotFoundError(`No data for workspace '${name}'`);
    }
    return { manifest };
  }

  /**
   * Update an existing workspace.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async updateWorkspace(
    request: UpdateWorkspaceRequest,
    options?: RequestOptions,
  ) {
    const { manifest } = request;
    const { data, response } = await this.client().then(c =>
      c.PATCH('/workspaces/{workspaceName}', {
        headers: this.headersFromOptions(options),
        params: { path: { workspaceName: manifest.metadata.name } },
        body: manifest,
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to update workspace: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Queue a workspace build.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async queueWorkspaceBuild(
    request: QueueProductBuildRequest,
    options?: RequestOptions,
  ) {
    const { name } = request;
    const { data, response } = await this.client().then(c =>
      c.POST('/workspaces/{workspaceName}/build', {
        headers: this.headersFromOptions(options),
        params: { path: { workspaceName: name } },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to queue workspace build: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Get MS Graph Groups.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async getMsgraphGroups(
    request: GetMsgraphGroupsRequest,
    options?: RequestOptions,
  ) {
    const { data, response } = await this.client().then(c =>
      c.GET('/msgraph/groups', {
        headers: this.headersFromOptions(options),
        params: { query: request },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to get MS Graph Groups: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Get MS Graph Groups that a specific user is a member/transitive member of.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async getTransitiveGroupMemberships(
    request: GetMsgraphUserMemberGroupsRequest,
    options?: RequestOptions,
  ) {
    const { userId, query } = request;
    const { data, response } = await this.client().then(c =>
      c.GET(`/msgraph/groups/{userId}`, {
        headers: this.headersFromOptions(options),
        params: { path: { userId }, query },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to get transitive group memberships: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Get MS Graph Users.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async getMsgraphUsers(
    request: GetMsgraphUsersRequest,
    options?: RequestOptions,
  ) {
    const { data, response } = await this.client().then(c =>
      c.GET('/msgraph/users', {
        headers: this.headersFromOptions(options),
        params: { query: request },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to get MS Graph Users: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Run all Argus Product pipelines.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async queueAllProductBuilds(
    request: QueueAllProductBuildsRequest,
    options?: RequestOptions,
  ) {
    const { data, response } = await this.client().then(c =>
      c.POST('/products/build', {
        headers: this.headersFromOptions(options),
        params: { query: request },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to queue all product builds: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }

  /**
   * Run all Argus Product pipelines.
   *
   * @param request - Request parameters
   * @param options - Additional options
   */
  async queueAllWorkspaceBuilds(
    request: QueueAllWorkspaceBuildsRequest,
    options?: RequestOptions,
  ) {
    const { data, response } = await this.client().then(c =>
      c.POST('/workspaces/build', {
        headers: this.headersFromOptions(options),
        params: { query: request },
      }),
    );
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      this.logger.error(
        `Failed to queue all workspace builds: ${response.statusText}`,
        err,
      );
      throw err;
    }
    if (!data) {
      throw new ConflictError('Service returned no data');
    }
    return data;
  }
}
