import {
  AnyDevhubManifest,
  isProductManifest,
  ProductManifest,
  AnyModuleConfigKind,
  AnyCapabilityConfigKind,
} from '../models';
import _ from 'lodash';

export const moduleDependencies: {
  [module in AnyModuleConfigKind]: AnyCapabilityConfigKind[];
} = {
  dagster: ['postgres', 'object-store'],
  mlflow: ['postgres', 'object-store'],
  jupyter: [],
  superset: ['postgres'],
  arango: ['object-store'],
  'ai-search': [],
  'ai-search-basic': [],
  'ai-multi-service': ['object-store'],
  cloudbeaver: [],
  memgraph: [],
  openai: [],
  redis: [],
};

const unlimitedModuleKeys = new Set(['ai-search', 'ai-search-basic']);

export function checkConstraints(manifest: AnyDevhubManifest) {
  if (isProductManifest(manifest)) {
    checkProductConstraints(manifest);
  }
}

function checkProductConstraints(manifest: ProductManifest) {
  const moduleKeys = manifest.modules.map(m => m.kind);
  const moduleCounts = _.countBy(moduleKeys);
  for (const [key, count] of Object.entries(moduleCounts)) {
    if (!unlimitedModuleKeys.has(key) && count > 1) {
      throw new Error(`Duplicate module keys found: ${key}`);
    }
  }

  const capabilityKeys = manifest.capabilities.map(c => c.kind);
  if (_.uniq(capabilityKeys).length !== capabilityKeys.length) {
    throw new Error('Duplicate capability keys found');
  }
}
