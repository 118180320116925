import {
  ANNOTATION_LOCATION,
  ANNOTATION_ORIGIN_LOCATION,
} from '@backstage/catalog-model';

const _CORE_NAMESPACE = 'core.argus.ai';
export const WORKSPACE_MANIFEST_KIND = 'WorkspaceManifest';
export const PRODUCT_MANIFEST_KIND = 'ProductManifest';
export const PLATFORM_MANIFEST_KIND = 'PlatformManifest';
export const GITHUB_ENTERPRISE_MANIFEST_KIND = 'GitHubEnterpriseManifest';
export const DSP_MANIFEST_KIND = 'DspManifest';

export class EnvelopeV1Alpha1 {
  public static readonly Namespace = 'core.argus.ai';
  public static readonly Version = 'v1alpha1';
  public static readonly ApiVersion = 'core.argus.ai/v1alpha1';
}

export class AnnotationsV1Alpha1 {
  public static readonly UniqueKey = `${_CORE_NAMESPACE}/argus-key`;
  public static readonly LinkConfig = `${_CORE_NAMESPACE}/config`;
  public static readonly LinkResourceInfo = `${_CORE_NAMESPACE}/resources`;
  public static readonly Status = `${_CORE_NAMESPACE}/provisioning-status`;
  public static readonly AdoServiceConnectionIds = `${_CORE_NAMESPACE}/ado-resource-ids`;
  public static readonly AdoPipelineId = `${_CORE_NAMESPACE}/ado-pipeline-id`;
  public static readonly AzureResourceGroup = `${_CORE_NAMESPACE}/azure-resource-group`;

  // devhub annotations
  public static readonly StackDiscriminator = 'devhub.io/discriminator';

  // well-known Backstage annotations
  public static readonly BackstageLocation = ANNOTATION_LOCATION;
  public static readonly BackstageOriginLocation = ANNOTATION_ORIGIN_LOCATION;
  public static readonly BackstageKubernetesLabelSelector =
    'backstage.io/kubernetes-label-selector';
  public static readonly BackstageKubernetesCluster =
    'backstage.io/kubernetes-cluster';
  public static readonly BackstageKubernetesNamespace =
    'backstage.io/kubernetes-namespace';

  // well-known Azure DevOps plugin annotations
  public static readonly AzureRepo = 'dev.azure.com/project-repo';
  public static readonly AzureBuild = 'dev.azure.com/build-definition';

  // MS Graph
  public static readonly MsGraphGroupUUID = 'graph.microsoft.com/group-id';
  public static readonly MsGraphUserUUID = 'graph.microsoft.com/user-id';
}

export class LabelsV1Alpha1 {
  public static readonly Workspace = `argus.io/workspace`;
  public static readonly Module = `devhub.io/stack-module`;
  public static readonly Capability = `devhub.io/stack-capability`;
}

export class ComponentTypes {
  public static readonly StackModule = 'stack-module';
  public static readonly StackCapability = 'stack-capability';
  public static readonly DagsterInstance = 'dagster-instance';
  public static readonly DagsterUserDeployment = 'dagster-user-deployment';
  public static readonly MlflowInstance = 'mlflow-instance';
  public static readonly Gateway = 'gateway';
}

export class ResourceTypes {
  public static readonly ExposedService = 'exposed-service';
  public static readonly GatewayDomain = 'gateway-domain';
}

export {
  AnnotationsV1Alpha1 as Annotations,
  EnvelopeV1Alpha1 as Envelope,
  LabelsV1Alpha1 as Labels,
};
