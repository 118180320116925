import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      width="80%"
      viewBox="0 0 80 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1809 1H10.3299C10.3028 1.00156 10.2749 1.00234 10.2461 1.00234H8.34725C7.02858 1.00234 5.99999 1.47707 5.26154 2.42652C4.57583 3.37596 4.23296 4.74739 4.23296 6.54079V20.9408C4.23296 22.0023 4.16733 22.973 4.03607 23.8528C3.86733 24.9839 3.59009 25.9649 3.20437 26.7957C3.1648 26.888 3.12381 26.9789 3.08137 27.0683C2.4447 28.4096 1.48352 29.4264 0.197819 30.1188C-0.0659397 30.2243 -0.0659397 30.3561 0.197819 30.5144C2.3157 31.6356 3.59987 33.7705 4.05037 36.9187C4.17208 37.7693 4.23296 38.6938 4.23296 39.6924V54.4879C4.23296 56.2813 4.57583 57.6527 5.26154 58.6022C5.99572 59.5986 7.0167 60.0997 8.32442 60.1054H33.1809C37.5766 60.1054 41.6212 59.5789 45.3147 58.5257H29.1766V44.5692H23.9774V16.5363H32.3366C35.1511 16.5363 37.5998 17.0711 39.6825 18.1406C41.8216 19.2101 43.4821 20.7863 44.6643 22.869C45.4142 24.1903 45.9263 25.6929 46.2004 27.3767H66.4059C66.0019 22.5957 64.6371 18.3911 62.3115 14.7631C59.4969 10.3724 55.5847 6.99498 50.5748 4.63077C45.5649 2.21026 39.767 1 33.1809 1Z"
        fill="#004A96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.6906 49.6302C50.1938 49.6531 49.678 49.6301 49.1426 49.5577C48.1869 35.0585 46.2225 31.3304 39.6391 27.7093C35.6796 25.5314 30.9321 24.0115 23.0226 27.7093C19.3963 24.8711 17.1953 21.1404 17.1953 17.054C17.1953 8.16639 27.6061 1 40.449 1C45.3166 1 49.8349 2.02943 53.5695 3.79183C58.0311 5.72588 62.4238 9.33666 65.8251 14.2744C73.1123 24.8535 71.5551 37.748 64.567 46.5578C59.3388 53.1488 49.3741 52.8886 47.8875 51.6671C48.9292 50.9697 49.8608 50.2918 50.6906 49.6302Z"
        fill="#6692C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5727 49.6346C52.8543 47.5624 55.0396 47.4319 57.0581 47.3114C60.3612 47.1143 63.2178 46.9437 65.3192 38.3352C66.6145 33.0284 66.916 24.0603 59.6732 16.4926C53.8015 10.3574 47.1728 9.52515 42.2689 9.52515C33.5123 9.52515 22.0552 18.3243 22.4823 26.9242C22.4823 27.045 22.4811 27.1592 22.4788 27.267C22.6566 27.4167 22.8382 27.5641 23.0236 27.7091C30.9331 24.0114 35.6805 25.5312 39.6401 27.7091C46.2235 31.3302 48.1879 35.0584 49.1435 49.5576C49.6364 49.6242 50.1126 49.649 50.5727 49.6346ZM49.1822 50.7706C49.4994 50.5426 49.8048 50.3167 50.0985 50.0926C49.8169 50.3809 49.534 50.7005 49.2498 51.055C49.2335 51.0528 49.2171 51.0507 49.2007 51.0485C49.1946 50.9554 49.1884 50.8628 49.1822 50.7706ZM48.5868 51.9106C48.7714 51.6647 48.9755 51.4004 49.2007 51.1165C49.2171 51.0959 49.2334 51.0754 49.2498 51.055C49.7357 51.1179 50.2043 51.1374 50.6563 51.1165C50.3162 51.3877 49.958 51.6617 49.581 51.9389C49.1966 51.9579 48.862 51.9485 48.5868 51.9106Z"
        fill="#336EAB"
      />
      <path
        d="M33.6077 30.8103C32.9017 24.7594 37.9931 20.65 44.0418 19.9438C50.0905 19.2376 53.7669 21.6437 55.7426 26.7688C53.8135 29.5537 51.0094 30.521 48.9012 30.6392C45.6551 30.8213 44.618 28.3433 40.7692 28.0838C37.9603 27.8945 35.5604 28.6526 33.6077 30.8103Z"
        fill="#003B78"
      />
      <path
        d="M36.2833 31.1866C35.5774 25.1357 40.6688 21.0263 46.7175 20.3201C48.2968 20.1357 49.6107 21.4444 51.5865 26.5694C49.6574 29.3544 50.4039 27.2607 48.2957 27.3789C45.0495 27.561 47.2937 28.7196 43.4449 28.4602C40.6359 28.2708 38.2361 29.0289 36.2833 31.1866Z"
        fill="#003B78"
      />
      <path
        d="M49.3973 21.3155C43.3486 22.0217 38.2571 26.1311 38.9631 32.1819C40.9159 30.0243 43.3157 29.2661 46.1246 29.4555C49.9734 29.715 47.0078 28.1967 50.2539 28.0146C50.3026 28.0119 50.3483 28.0098 50.3912 28.0084C51.5181 27.9708 50.7144 28.358 50.3912 28.0084C50.1933 27.7943 50.1755 27.304 50.8912 26.2708C48.9155 21.1457 51.5244 21.4611 49.3973 21.3155Z"
        fill="#003B78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.607 30.7914C33.6078 30.7977 33.6085 30.8039 33.6092 30.8102C35.562 28.6525 37.9618 27.8944 40.7707 28.0837C42.6673 28.2116 43.8811 28.8781 45.0129 29.4996C46.178 30.1394 47.2562 30.7315 48.9028 30.6391C51.0109 30.5209 53.815 29.5537 55.7441 26.7688C53.2989 25.1328 49.5338 23.4037 43.5493 24.1024C39.0234 24.6309 35.0334 27.0647 33.607 30.7914Z"
        fill="#003469"
      />
      <path
        d="M17.1984 16.7866C15.8021 22.8862 9.34499 28.9153 0.628906 30.3016C3.44947 32.1221 6.69678 33.1463 10.2404 33.1463C15.2419 33.1463 19.783 31.059 23.1303 27.6591C23.4522 26.5159 23.6396 24.5785 23.195 22.6368C19.2845 21.9 18.2485 20.1382 17.1984 16.7866Z"
        fill="#003B78"
      />
      <path
        d="M23.1953 22.6379L23.1951 22.6371C19.2846 21.9003 18.2486 20.1384 17.1985 16.7869C15.8088 22.8575 9.4062 28.8584 0.752825 30.2818C3.67349 31.7289 8.73589 30.7287 12.3261 29.6831C16.7225 28.4027 19.9349 25.9131 23.1953 22.6379Z"
        fill="#003871"
      />
      <path
        d="M69.5596 36.8348C51.8765 49.3808 37.2086 42.6763 23.3516 33.3288C13.2594 26.521 8.20108 60.1077 40.7089 60.1077C57.9107 60.1077 67.1705 45.6422 69.5596 36.8348Z"
        fill="#6692C0"
      />
      <ellipse
        cx="0.366441"
        cy="0.627603"
        rx="0.366441"
        ry="0.627603"
        transform="matrix(-0.258733 -0.965949 -0.965903 0.258905 8.5791 29.1375)"
        fill="#00254B"
      />
      <path
        d="M67.1971 42.8259C68.2828 40.706 69.0671 38.6502 69.5596 36.8348C52.3943 49.0135 38.0702 43.0525 24.571 34.1428C21.3156 41.7982 24.0813 54.278 44.4406 54.278C54.8613 54.278 62.3674 48.9694 67.1971 42.8259Z"
        fill="#B2C9DF"
      />
      <ellipse
        cx="1.22303"
        cy="2.00067"
        rx="1.22303"
        ry="2.00067"
        transform="matrix(-0.918968 -0.394333 -0.394097 0.919069 22.4619 14.6082)"
        fill="#00254B"
      />
    </svg>
  );
};

export default LogoIcon;
