import {
  AnyEntry,
  ProductEntry,
  ProductManifest,
  RegistryManifest,
  WorkspaceEntry,
  WorkspaceManifest,
  PlatformManifest,
  ProductCellManifest,
  DspManifest,
  InfrastructureCellEntry,
  PlatformEntry,
  GatewayEntry,
  InstanceEntry,
  InstanceCellEntry,
  GitHubEnterpriseManifest,
  GitHubEnterpriseEntry,
} from './manifests';
import {
  typesAnyCapabilityConfig,
  schemasAnyCapabilityConfig,
  typesAnyModuleConfig,
  schemasAnyModuleConfig,
  StagedConfigDict,
  typesAnyCapabilityConfigStaged,
} from './gen';
import { getTypeGuard } from './base';

export { registrySchema } from './gen';
export { getValidator, getJsonSchema } from './base';

export function isStagedConfigDict<C>(
  data: any,
  guard: (data: any) => data is C,
): data is StagedConfigDict<C> {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  if (!data.development || !guard(data.development)) {
    return false;
  }
  if (data.production !== undefined && !guard(data.production)) {
    return false;
  }
  return true;
}

export function isBaseCapabilityConfig<
  TKind extends keyof typesAnyCapabilityConfig,
>(data: any, kind: TKind): data is typesAnyCapabilityConfig[TKind] {
  return getTypeGuard<typesAnyCapabilityConfig[TKind]>(
    schemasAnyCapabilityConfig[kind],
  )(data);
}

export function isCapabilityConfig<
  TKind extends keyof typesAnyCapabilityConfig,
>(data: any, kind: TKind): data is typesAnyCapabilityConfigStaged[TKind] {
  const guard = getTypeGuard<typesAnyCapabilityConfig[TKind]>(
    schemasAnyCapabilityConfig[kind],
  );
  if (data.kind !== kind) {
    return false;
  }
  if (!data.config) {
    return false;
  }
  if (guard(data.config)) {
    return true;
  }
  if (isStagedConfigDict(data.config, guard)) {
    return true;
  }
  return false;
}

export function isModuleConfig<TKind extends keyof typesAnyModuleConfig>(
  data: any,
  kind: TKind,
): data is typesAnyModuleConfig[TKind] {
  return getTypeGuard<typesAnyModuleConfig[TKind]>(
    schemasAnyModuleConfig[kind],
  )(data);
}

// Note: all TypeGuards below need to be manually defined to avoid the error
// "Type instantiation is excessively deep and possibly infinite. ts(2589)"
// DO NOT auto-generate these types in the gen/ folder
export const isWorkspaceManifest = getTypeGuard<WorkspaceManifest>(
  '#/$defs/WorkspaceManifest',
);

export const isProductManifest = getTypeGuard<ProductManifest>(
  '#/$defs/ProductManifest',
);

export const isDspManifest = getTypeGuard<DspManifest>('#/$defs/DspManifest');

export const isPlatformManifest = getTypeGuard<PlatformManifest>(
  '#/$defs/PlatformManifest',
);

export const isProductCellManifest = getTypeGuard<ProductCellManifest>(
  '#/$defs/ProductCellManifest',
);

export const isGitHubEnterpriseManifest =
  getTypeGuard<GitHubEnterpriseManifest>('#/$defs/ProductCellManifest');

export function isRegistryManifest(
  manifest: any,
): manifest is RegistryManifest {
  return (
    isWorkspaceManifest(manifest) ||
    isProductManifest(manifest) ||
    isPlatformManifest(manifest) ||
    isProductCellManifest(manifest) ||
    isGitHubEnterpriseManifest(manifest) ||
    isDspManifest(manifest)
  );
}

export const isPlatformEntry = getTypeGuard<PlatformEntry>(
  '#/$defs/PlatformEntry',
);

export const isInstanceEntry = getTypeGuard<InstanceEntry>(
  '#/$defs/InstanceEntry',
);

export const isInfrastructureCellEntry = getTypeGuard<InfrastructureCellEntry>(
  '#/$defs/InfrastructureCellEntry',
);

export const isGatewayEntry = getTypeGuard<GatewayEntry>(
  '#/$defs/GatewayEntry',
);

export const isWorkspaceEntry = getTypeGuard<WorkspaceEntry>(
  '#/$defs/WorkspaceEntry',
);

export const isProductEntry = getTypeGuard<ProductEntry>(
  '#/$defs/ProductEntry',
);

export const isInstanceCellEntry = getTypeGuard<InstanceCellEntry>(
  '#/$defs/InstanceCellEntry',
);

export const isGitHubEnterpriseEntry = getTypeGuard<GitHubEnterpriseEntry>(
  '#/$defs/GitHubEnterpriseEntry',
);

export function isCatalogEntry(output: any): output is AnyEntry {
  return (
    isWorkspaceEntry(output) ||
    isProductEntry(output) ||
    isInfrastructureCellEntry(output) ||
    isPlatformEntry(output) ||
    isGitHubEnterpriseEntry(output)
  );
}
