import {
  CatalogApi,
  CatalogClient,
  CatalogRequestOptions,
} from '@backstage/catalog-client';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { NotFoundError } from '@backstage/errors';

import {
  GetProductEntityRequest,
  GetProductEntityResponse,
  GetWorkspaceEntityResponse,
  ListProductEntitiesResponse,
  ListWorkspaceEntitiesResponse,
  ListDspEntititiesResponse,
  ProvisioningCatalogApi,
  GetUserRequest,
  GetUserResponse,
  GetGroupRequest,
  GetGroupResponse,
} from '../api';
import {
  isProductEntity,
  isWorkspaceEntity,
  productEntityRef,
  workspaceEntityRef,
  isDevHubUserEntity,
} from '../models';
import {
  PRODUCT_ENTITY_KIND,
  PRODUCT_ENTITY_NAMESPACE,
  WORKSPACE_ENTITY_KIND,
  WORKSPACE_ENTITY_NAMESPACE,
  DSP_ENTITY_KIND,
  DSP_ENTITY_NAMESPACE,
  isDspEntity,
} from '../models/context';
import { stringifyEntityRef, isGroupEntity } from '@backstage/catalog-model';

interface CatalogRequestWithFilterOptions extends CatalogRequestOptions {
  filter?: Record<string, string | string[]>;
}

export class ProvisioningCatalogClient implements ProvisioningCatalogApi {
  private readonly catalogApi: CatalogApi;

  static fromOptions(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: FetchApi;
  }): ProvisioningCatalogClient {
    return new ProvisioningCatalogClient(new CatalogClient(options));
  }

  constructor(catalogApi: CatalogApi) {
    this.catalogApi = catalogApi;
  }

  getCatalogApi(): CatalogApi {
    return this.catalogApi;
  }

  async getProductEntity(
    request: GetProductEntityRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetProductEntityResponse> {
    const entityRef = productEntityRef(request.name);
    const entity = await this.catalogApi.getEntityByRef(entityRef, {
      token: options?.token,
    });
    if (!entity) {
      throw new NotFoundError(`Product '${request.name}' not found.`);
    }
    if (!isProductEntity(entity)) {
      throw new Error(
        `Entity with ref '${entityRef}' is not a product entity.`,
      );
    }
    return { entity };
  }

  async listProductEntities(
    options?: CatalogRequestWithFilterOptions,
  ): Promise<ListProductEntitiesResponse> {
    const { items } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: PRODUCT_ENTITY_KIND,
          'metadata.namespace': PRODUCT_ENTITY_NAMESPACE,
          ...(options?.filter ?? {}),
        },
      },
      { token: options?.token },
    );
    const check = items
      .map(it => isProductEntity(it))
      .reduce((a, b) => a && b, true);
    if (!check) {
      throw new Error('Some entities are not product entities');
    }
    // @ts-expect-error we checked above that all entities are product entities
    return { entities: items };
  }

  async listDspEntities(
    options?: CatalogRequestWithFilterOptions,
  ): Promise<ListDspEntititiesResponse> {
    const { items } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: DSP_ENTITY_KIND,
          'metadata.namespace': DSP_ENTITY_NAMESPACE,
          ...(options?.filter ?? {}),
        },
      },
      { token: options?.token },
    );
    const check = items
      .map(it => isDspEntity(it))
      .reduce((a, b) => a && b, true);
    if (!check) {
      throw new Error('Some entities are not DSP entities');
    }
    // @ts-expect-error we checked above that all entities are product entities
    return { entities: items };
  }

  async getWorkspaceEntity(
    request: GetProductEntityRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetWorkspaceEntityResponse> {
    const entityRef = workspaceEntityRef(request.name);
    const entity = await this.catalogApi.getEntityByRef(entityRef, {
      token: options?.token,
    });
    if (!entity) {
      throw new NotFoundError(`Workspace '${request.name}' not found.`);
    }
    if (!isWorkspaceEntity(entity)) {
      throw new Error(
        `Entity with ref '${entityRef}' is not a workspace entity.`,
      );
    }
    return { entity };
  }

  async listWorkspaceEntities(
    options?: CatalogRequestWithFilterOptions,
  ): Promise<ListWorkspaceEntitiesResponse> {
    const { items } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: WORKSPACE_ENTITY_KIND,
          'metadata.namespace': WORKSPACE_ENTITY_NAMESPACE,
          ...(options?.filter ?? {}),
        },
      },
      { token: options?.token },
    );
    const check = items
      .map(it => isWorkspaceEntity(it))
      .reduce((a, b) => a && b, true);
    if (!check) {
      throw new Error('Some entities are not workspace entities');
    }
    // @ts-expect-error we checked the required fields in the filter
    return { entities: items };
  }

  async getUser(
    request: GetUserRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetUserResponse> {
    const metaKeyId = 'metadata.annotations.graph.microsoft.com/user-id';
    const metaKeyEmail = 'metadata.annotations.microsoft.com/email';
    const { items } = await this.catalogApi.getEntities(
      {
        filter: [
          { kind: 'User', [metaKeyId]: request.userIdOrMail },
          { kind: 'User', [metaKeyEmail]: request.userIdOrMail },
        ],
      },
      options,
    );

    if (items.length === 0) {
      throw new NotFoundError(`User '${request.userIdOrMail}' not found.`);
    }
    if (items.length > 1) {
      throw new Error(`Multiple users found for '${request.userIdOrMail}'.`);
    }
    if (!isDevHubUserEntity(items[0])) {
      const entityRef = stringifyEntityRef(items[0]);
      throw new Error(`Entity with ref '${entityRef}' is not a user entity.`);
    }
    return { user: items[0] };
  }

  async getGroup(
    request: GetGroupRequest,
    options?: CatalogRequestOptions,
  ): Promise<GetGroupResponse> {
    const metaKeyId = 'metadata.annotations.graph.microsoft.com/group-id';
    const { items } = await this.catalogApi.getEntities(
      {
        filter: [{ kind: 'Group', [metaKeyId]: request.groupId }],
      },
      options,
    );

    if (items.length === 0) {
      throw new NotFoundError(`Group '${request.groupId}' not found.`);
    }
    if (items.length > 1) {
      throw new Error(`Multiple groups found for '${request.groupId}'.`);
    }
    if (!isGroupEntity(items[0])) {
      const entityRef = stringifyEntityRef(items[0]);
      throw new Error(`Entity with ref '${entityRef}' is not a group entity.`);
    }
    return { group: items[0] };
  }
}
