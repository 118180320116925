import addFormats from 'ajv-formats';
import { Ajv2020, Logger } from 'ajv/dist/2020';
import { registrySchema } from '../gen/registrySchema';

let validator: Ajv2020 | null = null;

/**
 * Get the ajv instance configured for validating data.
 *
 * This validator will not add default values for fields.
 * To add default values, use the ManifestHandler.
 *
 * @param logger - Optional logger to use for validation errors.
 */
export function getValidator(logger?: Logger) {
  if (!validator) {
    validator = new Ajv2020({
      strict: false,
      // https://ajv.js.org/security.html#security-risks-of-trusted-schemas
      allErrors: false,
      removeAdditional: true,
      coerceTypes: 'array',
      // This HAS to be false, if you need a validator with defaults,
      // use the one defined in the manifest handler module.
      useDefaults: false,
      verbose: false,
      logger,
      discriminator: false,
      schemas: [registrySchema],
    });
    addFormats(validator);
  }
  if (!validator.logger && logger) validator.logger = logger;
  return validator;
}

export function getJsonSchema(typeDefKey: string) {
  const validate = getValidator().getSchema(typeDefKey);
  if (!validate) {
    throw new Error(
      `Failed to resolve schema from registry for key: '${typeDefKey}'`,
    );
  }
  if (isPromise(validate)) {
    throw new Error('Validation returned a promise');
  }
  return {
    $defs: registrySchema.$defs,
    // @ts-expect-error
    ...validate.schema,
  };
}

function isPromise(obj: any): obj is Promise<any> {
  return typeof obj === 'object' && typeof obj.then === 'function';
}

export function getTypeGuard<T>(typeDefKey: string) {
  const validate = getValidator().getSchema<T>(typeDefKey);
  if (!validate) {
    throw new Error(
      `Failed to resolve schema from regsity for key: '${typeDefKey}'`,
    );
  }
  return Object.assign(
    (data: any): data is T => {
      const valid = validate(data);
      if (isPromise(valid)) {
        throw new Error('Validation returned a promise');
      }
      return valid;
    },
    { schemaRef: typeDefKey },
  );
}
